import JwtService from "@/core/services/JwtService";

const user = JwtService.getUser() ? JSON.parse(JwtService.getUser()) : null; 

const AdminMenu = {
  'BackOffice':[


    {
      heading:'YTM Backoffice',
      route:'/clients',
      pages:[
        {
          heading:'Danışanlar',
          route:'/clients',
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",
        },

        {
          heading:'Görüşmeler',
          route:'/calls',
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",
        },
  
        {
          heading:'Satışlar',
          route:'/sales',
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",
        },

      ]
    },
  
    
  
    
  
    
  ],
  'Admin':[


    {
      heading:'Kullanıcılar',
      route:'/clients',
      pages:[
        {
          heading:'Danışanlar',
          route:'/clients',
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",
        },

        {
          heading:'Kullanıcı grupları',
          route:'/user-groups',
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",
        },

        {
          sectionTitle:'Danışmanlar',
          
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",
          sub:[
            {
              heading:'Hemen Görüşme Danışmanları',
              route:'/consultants/5',
              svgIcon: "media/icons/duotune/general/gen022.svg",
              fontIcon: "bi-archive",
            },
            {
              heading:'Uzman Danışmanlar',
              route:'/consultants/6',
              svgIcon: "media/icons/duotune/general/gen022.svg",
              fontIcon: "bi-archive",
            },
            
  
          ]
  
        },
  
        {
          heading:'Sistem Kullanıcıları',
          route:'/system-users',
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",
        },
      ]
    },
  
    {
      heading:'Raporlar',
      route:'/clients',
      pages:[
        {
          heading:'Görüşmeler',
          route:'/calls',
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",
        },
  
        {
          heading:'Satışlar',
          route:'/sales',
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",
        },
  
       
        
      ]
    },
  
    {
      heading: "Ayarlar",
      route: "/mobile-settings",
      pages: [   
  
        {
          heading: "Paketler",
          route: "/packages",
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",     
        },

        {
          heading: "Kategoriler",
          route: "/categories",
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",     
        },
  
        {
          heading: "Uzman Danışman Tipleri",
          route: "/consultant-types",
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",     
        },
  
        {
          heading: "Sıkça Sorulan Sorular",
          route: "/faq",
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",     
        },
  
        {
          heading: "Sözleşmeler",
          route: "/contracts",
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",     
        },
  
        {
          heading: "Mobil Uygulama Ayarları",
          route: "/mobile-settings",
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",     
        },
  
        
        {
          heading: "Slaytlar",
          route: "/mobile-slider",
          svgIcon: "media/icons/duotune/general/gen022.svg",
          fontIcon: "bi-archive",     
        },
  
  
        
  
      ],
    },
  
    
  ]

}

const DocMenuConfig = user ? AdminMenu[user.lUserType] : {};

export default DocMenuConfig;
